(function ($) {
	"use strict";
	
	var windowWidth = $(window).innerWidth(),
	    windowHeight = $(window).innerHeight();
	
	/*-- BACK TOP --*/
    $(".site-back-top").on("click", function (e) {
        e.preventDefault();
		$("body,html").animate({scrollTop: 0}, 800);
    });
	
	function backTop() {
		if ($(window).scrollTop() > 40 && windowWidth > 767) {
			$(".site-back-top").fadeIn();
		} else {
			$(".site-back-top").fadeOut();
		}
	}
	/*-- BACK TOP END --*/
	
	/*-- HEADER LANG --*/
    $(".site-header .header-top .lang-select").on({
        mouseenter: function () { $(this).addClass("active"); },
        mouseleave: function () { $(this).removeClass("active"); }
    });
    
    $(".site-header .header-top .lang-select .active a").on("click", function (e) {
        e.preventDefault();
        var parent = $(".site-header .header-top .lang-select");
        
        if (parent.hasClass("active")) {
            parent.removeClass("active");
        } else {
            parent.addClass("active");
        }
    });
	/*-- HEADER LANG END --*/
    
    /*-- HEADER MENU --*/
    $(".site-header .header-bottom .hb-toggle").on("click", function () {
        var parent = $(".site-header"),
            target = $(".site-header .hb-menu");
        
        if (parent.hasClass("menu-active")) {
            $(parent).removeClass("menu-active");
        } else {
            parent.addClass("menu-active");
        }
    });
    
    $(".site-header .hb-menu li.sub > a, .site-header .hb-menu li.megamenu > a").on("click", function (e) {
        e.preventDefault();
        var parent = $(this).parent("li");
        
        if (parent.hasClass("active")) {
            $("body,html").animate({scrollTop: 0}, 200, function () {
                $(".hb-menu li").removeClass("active");
            });
        } else {
            $("body,html").animate({scrollTop: 0}, 200, function () {
                $(".hb-menu li").removeClass("active");
                parent.addClass("active");
            });
        }
    });
    /*-- HEADER MENU --*/
    
    /*-- HEADER BANNER --*/
    $(".site-banner .banner-image").each(function () {
        var src = $(this).find("img").attr("src");
        $(this).css("background-image", "url(" + src + ")");
    });
    /*-- HEADER BANNER END --*/
	
	/*-- SLIDER --*/
    function sliderPager() {
		// Create Pager Template
		var tmp = [];
		tmp.push('<div class="bx-pager-thumbs">');
		tmp.push('<div class="site-wrapper">');
		tmp.push('<ul class="clearfix">');
		
		// Add Pager Item
		$(".site-slider .slide-image img").each(function (index, element) {
            var src = $(this).attr("src");
			
			tmp.push('<li><a data-slide-index="' + index + '" href="#" style="background-image: url(' + src + ')"></a></li>');
        });
		
		tmp.push('</ul>');
		tmp.push('</div>');
		tmp.push('</div>');
			
		// Add Pager Template
		$(".site-slider").append(tmp.join('\n'));
		
		// Pager Width
		var total = $(".site-slider .slide-image").size();
		$(".site-slider .bx-pager-thumbs li").css("width", (100 / total) + "%");
	}
    
    function sliderBackground() {
		$(".site-slider .bxslider li").each(function () {
            var src = $(this).find("img").attr("src");
			$(this).css("background-image", "url(" + src + ")");
        });
	}
    
    if ($(".site-slider").size()) {
        
        // Create Pager
        //sliderPager();
        
        // Call Slider
        $(".site-slider .bxslider").bxSlider({
            //pagerCustom: '.bx-pager-thumbs',
pager: true,
            mode: "fade",
            captions: false,
            controls: true,
            auto: true,
            autoControls: true,
            autoHover: true,
            adaptiveHeight: true,
            nextText: '',
            prevText: '',
            onSlideAfter: function () {
                $(".bx-start").trigger("click");
            },
            onSliderLoad: function () {
                sliderBackground();
            }
        });
    }
	/*-- SLIDER END --*/
	
	/*-- CAROUSEL PRODUCTS --*/
	$("#owl-products").owlCarousel({
		//Most Features
		items : 1,
		itemsDesktop : [1199, 1],
		itemsDesktopSmall : [980, 1],
		itemsTablet: [768, 1],
		itemsMobile : [479, 1],
	 
		//Autoplay
		autoPlay : true,
		stopOnHover : true,
		
		// Navigation
		navigation : false,
		navigationText : ["", ""],
	 
		//Pagination
		pagination : true
	});
	/*-- CAROUSEL PRODUCTS END --*/
	
	/*-- CAROUSEL SERVICES --*/
	$("#owl-services").owlCarousel({
		//Most Features
		items : 3,
		itemsDesktop : [1199, 3],
		itemsDesktopSmall : [980, 2],
		itemsTablet: [768, 1],
		itemsMobile : [479, 1],
	 
		//Autoplay
		autoPlay : true,
		stopOnHover : true,
	 
		// Navigation
		navigation : true,
		navigationText : ["", ""],
	 
		//Pagination
		pagination : false
	});
	/*-- CAROUSEL SERVICES END --*/
	
	/*-- CAROUSEL TEAM --*/
	$("#owl-team").owlCarousel({
		//Most Features
		items : 3,
		itemsDesktop : [1199, 3],
		itemsDesktopSmall : [980, 2],
		itemsTablet: [768, 2],
		itemsMobile : [479, 1],
	 
		//Autoplay
		autoPlay : true,
		stopOnHover : true,
	 
		// Navigation
		navigation : true,
		navigationText : ["", ""],
	 
		//Pagination
		pagination : false
	});
	/*-- CAROUSEL TEAM END --*/
	
	/*-- CAROUSEL CLIENTS --*/
	$("#owl-clients").owlCarousel({
		//Most Features
		items : 4,
		itemsDesktop : [1199, 4],
		itemsDesktopSmall : [980, 3],
		itemsTablet: [768, 2],
		itemsMobile : [479, 1],
	 
		//Autoplay
		autoPlay : true,
		stopOnHover : true,
	 
		// Navigation
		navigation : true,
		navigationText : ["", ""],
	 
		//Pagination
		pagination : false
	});
	/*-- CAROUSEL CLIENTS END --*/
	
	/*-- CAROUSEL BLOG --*/
	$("#owl-blog").owlCarousel({
		//Most Features
		items : 1,
		itemsDesktop : [1199, 1],
		itemsDesktopSmall : [980, 1],
		itemsTablet: [768, 1],
		itemsMobile : [479, 1],
	 
		//Autoplay
		autoPlay : true,
		stopOnHover : true,
	 
		// Navigation
		navigation : true,
		navigationText : ["", ""],
	 
		//Pagination
		pagination : false
	});
	/*-- CAROUSEL BLOG END --*/
	
	/*-- CAROUSEL TESTIMONIALS --*/
	$("#owl-testimonials").owlCarousel({
		//Most Features
		items : 2,
		itemsDesktop : [1199, 2],
		itemsDesktopSmall : [980, 2],
		itemsTablet: [768, 1],
		itemsMobile : [479, 1],
	 
		//Autoplay
		autoPlay : true,
		stopOnHover : true,
	 
		// Navigation
		navigation : true,
		navigationText : ["", ""],
	 
		//Pagination
		pagination : false
	});
	/*-- CAROUSEL TESTIMONIALS END --*/
	
	/*-- CAROUSEL POST --*/
	$("#owl-post").owlCarousel({
		//Most Features
		items : 1,
		itemsDesktop : [1199, 1],
		itemsDesktopSmall : [980, 1],
		itemsTablet: [768, 1],
		itemsMobile : [479, 1],
	 
		//Autoplay
		autoPlay : true,
		stopOnHover : true,
	 
		// Navigation
		navigation : true,
		navigationText : ["", ""],
	 
		//Pagination
		pagination : false
	});
	/*-- CAROUSEL POST END --*/
    
    /*-- CAROUSEL WORK --*/
	$("#owl-work").owlCarousel({
		//Most Features
		items : 1,
		itemsDesktop : [1199, 1],
		itemsDesktopSmall : [980, 1],
		itemsTablet: [768, 1],
		itemsMobile : [479, 1],
	 
		//Autoplay
		autoPlay : true,
		stopOnHover : true,
	 
		// Navigation
		navigation : true,
		navigationText : ["", ""],
	 
		//Pagination
		pagination : false
	});
	/*-- CAROUSEL WORK END --*/
	
    /*-- WORKS FILTER --*/
    $(".works-filter ul li a").on("click", function (e) {
        $(".works-filter ul li").removeClass("active");
        $(this).parent("li").addClass("active");
        var target = $(this).attr("class"),
            items = $(".works-list .work"),
            selected = $(".works-list div." + target);
        
        if (target == "all") {
            items.removeClass("disable");
        } else {
            items.addClass("disable");
            selected.removeClass("disable");
        }
        
        e.preventDefault();
    });
	/*-- WORKS FILTER --*/
    
	/*-- COUNT TO --*/
	$(".counter").countTo();
	/*-- COUNT TO END --*/
	
	/*-- FIT VIDEO --*/
	$(".video-full").fitVids();
	/*-- FIT VIDEO END --*/
    
    /*-- POPUP --*/
	$(".popup-image").magnificPopup({
        type: 'image'
    });
    $(".popup-gallery").each(function () {
        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    });
	/*-- POPUP END --*/
	
	/*-- GOOGLE MAP --*/
	/*$(".google-map").width("100%").height("400px").gmap3({
		map: {
			options: {
                center: [51.5209564, 0.157134],
                zoom: 15,
                scrollwheel: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            }
        },
		marker: {
			latLng: [51.5209564, 0.157134]
		}
	});*/
	/*-- GOOGLE MAP END --*/
  
	/*-- WINDOW SCROLL --*/
	$(window).scroll(function () {
		backTop();
	});
	/*-- WINDOW SCROLL END --*/
	
	/*-- WINDOW LOAD --*/
	$(window).load(function () {
		$(".site-loader").delay(100).fadeOut("slow");
	});
	/*-- WINDOW LOAD END --*/
	
	/*-- WINDOW RESIZE --*/
	$(window).resize(function () {
        
	});
	/*-- WINDOW RESIZE END --*/
  
})(jQuery);